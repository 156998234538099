import { __decorate } from "tslib";
import { logStore, mainStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { differenceInDays } from 'date-fns';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.selectPeriodDate = [];
        this.loading = false;
        this.search = '';
        this.headers = [
            { text: 'Criado em', value: 'created_at' },
            { text: 'Criado_por', value: 'created_by_name' },
            { text: 'Autorizado por', value: 'allowed_by_name' },
            { text: 'Contexto', value: 'model' },
            { text: 'Operação', value: 'operation_id' },
        ];
        this.expandedHeaders = [
            { text: 'Campo', value: 'key' },
            { text: 'Valor', value: 'value' }
        ];
        this.items = [];
        this.operations = [
            { id: 1, name: 'Alteração' },
            { id: 2, name: 'Remoção' }
        ];
        this.operacaoSelected = [];
        this.modelsSelected = [];
        this.operacaoOptions = [
            { nome: 'Criação', id: null },
            { nome: 'Alteração', id: 1 },
            { nome: 'Remoção', id: 2 },
        ];
        this.modelOptions = [
            { nome: 'Financeiro', models: ['Financeiro', 'FinanceiroParcela', 'FinanceiroParcelaMovimentacao'] },
            { nome: 'Caixa', models: ['Caixa', 'CaixaMovimentacao', 'CaixaMovimentacaoDetalhamento'] },
            { nome: 'Turma', models: ['Turma', 'TurmaAluno', 'TurmaAtividade', 'TurmaAlunoAtividade'] },
            { nome: 'Usuário', models: ['User', 'UserAction', 'UserTool'] },
            { nome: 'Aluno', models: ['Aluno', 'Contrato', 'ContratoLivro'] },
            { nome: 'Comercial', models: ['Prospex', 'ParceriaCupom'] },
        ];
        this.contextoLabel = [
            { key: 'Financeiro', label: 'Financeiro' },
            { key: 'FinanceiroParcela', label: 'Financeiro - Parcela' },
            { key: 'FinanceiroParcelaMovimentacao', label: 'Financeiro - Movimentação de parcelas' },
            { key: 'Caixa', label: 'Caixa' },
            { key: 'CaixaMovimentacao', label: 'Caixa - Movimentação' },
            { key: 'CaixaMovimentacaoDetalhamento', label: 'Caixa - Detalhamento de movimentação' },
            { key: 'Turma', label: 'Turma' },
            { key: 'TurmaAluno', label: 'Turma - Aluno' },
            { key: 'TurmaAtividade', label: 'Turma - Atividade' },
            { key: 'TurmaAlunoAtividade', label: 'Turma - Atividade do aluno' },
            { key: 'User', label: 'Usuário' },
            { key: 'UserAction', label: 'Usuário - Ação' },
            { key: 'UserTool', label: 'Usuário - Módulo' },
            { key: 'Aluno', label: 'Aluno' },
            { key: 'Contrato', label: 'Aluno - Contrato' },
            { key: 'ContratoLivro', label: 'Aluno - Estágio' },
            // @ts-ignore
            { key: 'Prospex', label: `Comercial - ${this.getNomeProspex()}` },
            { key: 'ParceriaCupom', label: 'Comercial - Cupom' },
        ];
        this.redirectOptions = [
            { key: 'aluno_nome', model: 'Aluno', redirectModel: 'Aluno', extra_key: null, before_key: null, after_key: null },
            { key: 'aluno_nome', model: 'Financeiro', redirectModel: 'Aluno', extra_key: 'aluno_id', before_key: null, after_key: null },
            { key: 'aluno_nome', model: 'FinanceiroParcela', redirectModel: 'Aluno', extra_key: 'aluno_id', before_key: null, after_key: null },
            { key: 'aluno_nome', model: 'FinanceiroParcelaMovimentacao', redirectModel: 'Aluno', extra_key: 'aluno_id', before_key: null, after_key: null },
            { key: 'aluno_nome', model: 'User', redirectModel: 'Aluno', extra_key: 'aluno_id', before_key: null, after_key: null },
            { key: 'user_name', model: 'UserAction', redirectModel: 'Colaboradores', extra_key: null, before_key: null, after_key: 'user_id' },
            { key: 'user_name', model: 'UserTool', redirectModel: 'Colaboradores', extra_key: null, before_key: null, after_key: 'user_id' },
            { key: 'prospex_nome', model: 'Prospex', redirectModel: 'Prospex', extra_key: null, before_key: null, after_key: null },
            { key: 'cupom_nome', model: 'ParceriaCupom', redirectModel: 'Cupons', extra_key: 'cupom_id', before_key: null, after_key: null },
            { key: 'caixa_id', model: 'CaixaMovimentacaoDetalhamento', redirectModel: 'Caixa', extra_key: 'caixa_id', before_key: null, after_key: null },
            { key: 'caixa_id', model: 'CaixaMovimentacao', redirectModel: 'Caixa', extra_key: 'caixa_id', before_key: null, after_key: null },
            { key: 'caixa_id', model: 'FinanceiroParcelaMovimentacao', redirectModel: 'Caixa', extra_key: 'caixa_id', before_key: null, after_key: null },
            { key: 'turma_nome', model: 'Turma', redirectModel: 'Turma', extra_key: null, before_key: null, after_key: null },
            { key: 'turma_id', model: 'Turma', redirectModel: 'Turma', extra_key: 'turma_id', before_key: null, after_key: null },
            { key: 'turma_id', model: 'TurmaAtividade', redirectModel: 'Turma', extra_key: 'turma_id', before_key: null, after_key: null },
            { key: 'turma_id', model: 'TurmaAlunoAtividade', redirectModel: 'Turma', extra_key: 'turma_id', before_key: null, after_key: null },
            { key: 'aluno_id', model: 'TurmaAlunoAtividade', redirectModel: 'Aluno', extra_key: 'aluno_id', before_key: null, after_key: null },
            { key: 'user_name', model: 'User', redirectModel: 'Colaboradores', extra_key: null, before_key: null, after_key: null },
        ];
        this.filtroUserSelected = null;
    }
    getEntidadeId(log, target) {
        const redirectItem = _.find(this.redirectOptions, (el) => el.key == target.key && log.model == el.model);
        if (redirectItem.extra_key) {
            return log.extra[redirectItem.extra_key];
        }
        else if (redirectItem.before_key) {
            return log.before[redirectItem.before_key];
        }
        else if (redirectItem.after_key) {
            return log.after[redirectItem.after_key];
        }
        else {
            return log.model_id;
        }
    }
    getEntidadeNome(log, target) {
        const redirectItem = _.find(this.redirectOptions, (el) => el.key == target.key && log.model == el.model);
        return log.extra[redirectItem.key];
    }
    getEntidadeTipo(log, target) {
        const redirectItem = _.find(this.redirectOptions, (el) => el.key == target.key && log.model == el.model);
        return redirectItem.redirectModel;
    }
    convertDictToArray(jsonObject) {
        return Object.entries(jsonObject)
            .map(([key, value]) => ({ key, value }))
            .sort((a, b) => a.key.localeCompare(b.key));
    }
    async buscar() {
        const dataInicial = new Date(`${this.selectPeriodDate[0]}T00:00:00Z`);
        const dataComparacao = new Date(`${this.selectPeriodDate[1]}T00:00:00Z`);
        const diferenca = differenceInDays(dataComparacao, dataInicial);
        if (diferenca > 60) {
            const loadingNotification = {
                content: 'A diferença entre as datas não pode exceder 60 dias.',
                color: 'error',
            };
            mainStore.addNotification(loadingNotification);
            return;
        }
        this.loading = true;
        this.items = await logStore.getLogBigQuery({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
            created_by: this.filtroUserSelected ? this.filtroUserSelected.id : null,
            operacao: this.operacaoSelected.map((item) => item.id),
            model: this.modelsSelected.flatMap((obj) => obj.models),
        });
        this.loading = false;
    }
    async mounted() {
        this.modelsSelected = this.modelOptions;
        this.operacaoSelected = this.operacaoOptions;
    }
};
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            SearchEntidadeComponent,
            EntidadeLinkComponent,
            OpenNewTabComponent
        },
    })
], List);
export default List;
